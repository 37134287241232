body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.update_button {
  margin-bottom: 20px;
  padding: 10px;
  font-size: 10px;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  padding: 16px 43px;
  border: 1px solid white;
  border-radius: 28px;
  background-color: #0353a8;
  cursor: pointer;
}
.button_section {
  margin-left: 50%;
  margin-top: 20px;
  display: flex;
}
@media (max-width: 600px) {
  .button_section {
    margin-left: 0;
    justify-content: flex-end;
    margin-right: 5px;
  }
}
